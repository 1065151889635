<template>
  <div>
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
    >
      <el-tab-pane
        label="城市排行"
        name="first"
      >
        <div style="padding:10px" class="table-box">
          <el-table
            :data="cityData"
            border
            height="560px"
            style="width: 100%"
          >
            <el-table-column
              type="index"
              label="排名"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="城市"
            >
            </el-table-column>
            <el-table-column
              prop="tbl"
              label="填报率得分"
            >
             <template slot-scope="scope">
              <span>{{scope.row.tbl?scope.row.tbl:'-'}}</span>
            </template>
            </el-table-column>
            <!-- <el-table-column
              prop="zql"
              label="准确率得分"
            >
             <template slot-scope="scope">
              <span>{{scope.row.zql?scope.row.zql:'-'}}</span>
            </template>
            </el-table-column> -->
            <el-table-column
              prop="zf"
              label="总分"
            >
             <template slot-scope="scope">
              <span>{{scope.row.zf?scope.row.zf:'-'}}</span>
            </template>
            </el-table-column>
          </el-table>
        </div>

      </el-tab-pane>
      <el-tab-pane
        label="区县排行"
        name="second"
        
      >
        <div style="padding:10px">
          <el-table
            :data="districtData"
            border
            height="560px"
            style="width: 100%"
          >
            <el-table-column
              type="index"
              label="排名"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="区县"
            >
            </el-table-column>
            <el-table-column
              prop="pname"
              label="城市"
            >
            </el-table-column>
            <el-table-column
              prop="tbl"
              label="填报率得分"
            >
             <template slot-scope="scope">
              <span>{{scope.row.tbl?scope.row.tbl:'-'}}</span>
            </template>
            </el-table-column>
            <!-- <el-table-column
              prop="zql"
              label="准确率得分"
            >
            <template slot-scope="scope">
              <span>{{scope.row.zql?scope.row.zql:'-'}}</span>
            </template>
            </el-table-column> -->
            <el-table-column
              prop="zf"
              label="总分"
            >
             <template slot-scope="scope">
              <span>{{scope.row.zf?scope.row.zf:'-'}}</span>
            </template>
            </el-table-column>
          </el-table>
        </div>

      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data(){
    return{
      activeName:'first',
      cityData: [],
      districtData: []
    }
  },
  methods:{
    onLoad(l1,l2){
      console.log(';;;0', l1,l2);
      this.cityData = l1
      this.districtData = l2
    },
    handleClick(){},
  }
}
</script>
<style scoped>
.table-box{
  /* height: 600px;
  overflow-y: auto; */
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 8px; /*滚动条宽度*/
  height: 8px; /*滚动条高度*/
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
  border-radius: 0px; /*滚动条的背景区域的圆角*/
  background-color: #fff; /*滚动条的背景颜色*/
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-corner {
  background-color: #fff;
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 4px; /*滚动条的圆角*/
  background-color: #ddd; /*滚动条的背景颜色*/
}
</style>