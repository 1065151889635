<template>
  <div>
    <el-row
      type="flex"
      :gutter="24"
      style="margin-bottom:10px"
    >
      <el-col :span="12">
        <div class="box">
          全省样本企业填报率（{{reportSampleCompanyNumberObj.number}}）
          <div class="cavasbox" ref="reportSampleCompanyNumber"></div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="box">
          全省样本企业填报数量（{{reportCompanyNumberObj.number}}）
          <div class="cavasbox" ref="reportCompanyNumber"></div>
        </div>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      :gutter="24"
    >
      <el-col :span="24">
        <div class="box">
          全省样本企业数量（{{companyNumberObj.number}}）
          <div
            class="cavasbox"
            ref="companyNumber"
          ></div>
        </div>
      </el-col>
      <!-- <el-col :span="12">
        <div class="box">
          全省样本企业填报准确率（{{reportSampleCompanyAccuracyObj.number}}%）
          <div class="cavasbox" ref="reportSampleCompanyAccuracy"></div>
        </div>
      </el-col> -->
    </el-row>

  </div>
</template>

<script>
import * as Chart from 'echarts'
export default {
  data(){
    return{
      companyNumberObj:{},
      reportCompanyNumberObj:{},
      reportSampleCompanyNumberObj:{},
      reportSampleCompanyAccuracyObj:{}
    }
  },
  mounted(){
    
  },
  methods:{
    onLoad(value){
      console.log('value', value);
      this.reportSampleCompanyNumberObj = value.k3
      this.reportSampleCompanyAccuracyObj = value.k4
      //this.reportSampleCompanyNumberFun()
      // this.reportSampleCompanyAccuracyFun()
      
     
    },
    onLoad2(value){
			console.log('value',value);
      this.companyNumberObj = value.k1
      this.reportCompanyNumberObj = value.k2
      this.reportSampleCompanyNumberObj.number = value.ybSubmitRate
      this.companyNumberFun()
      this.reportCompanyNumberFun()
			this.reportSampleCompanyNumberFun()
    },
    // 全省企业数量
    companyNumberFun(){
      this.companyChart = Chart.init(this.$refs.companyNumber)
      this.companyNumberOption()
      
    },
    companyNumberOption(){
      var xData=[]
      var data=[]
      this.companyNumberObj.data.map(item =>{
        if(item.name){
          xData.push(item.name)
          data.push(item.number)
        }
      })
      this.companyChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data:xData,
            axisLabel:{
              interval:0,
              rotate: 20
            },
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '全省企业数量',
            type: 'bar',
            barWidth: '60%',
            data: data,
            itemStyle:{
              color:'#1890ff'
            }
          }
        ]
      })
    },
    // 全省填报企业数量
    reportCompanyNumberFun(){
      this.reportCompanyChart = Chart.init(this.$refs.reportCompanyNumber)
      this.reportCompanyNumberOption()
    },
    reportCompanyNumberOption(){
      var xData=[]
      var data=[]
      this.reportCompanyNumberObj.data.map(item =>{
        if(item.name){
          xData.push(item.name)
          data.push(item.number)
        }
      })
      this.reportCompanyChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            axisLabel:{
              interval:0,
              rotate: 20
            },
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '全省企业填报数量',
            type: 'bar',
            barWidth: '60%',
            data: data,
            itemStyle:{
              color:'#1890ff'
            }
          }
        ]
      })
    },
    // 全省样本企业填报率
    reportSampleCompanyNumberFun(){
      this.reportSampleCompanyChart = Chart.init(this.$refs.reportSampleCompanyNumber)
      this.reportSampleCompanyNumberOption()
    },
    reportSampleCompanyNumberOption(){
      var xData=[]
      var data=[]
			let list = []
			//全省填报企业数量
			const reportCompanyNumberObj = this.reportCompanyNumberObj.data
			//全省企业数量
			const companyNumberObj = this.companyNumberObj.data
			reportCompanyNumberObj.map(val=>{
				companyNumberObj.map(vam=>{
					if(val.code === vam.code){
						var params = {
							code:val.code,
							name:val.name,
							number:((val.number/vam.number)*100).toFixed(2)
						}
						list.push(params)
					}
				})
			})

      //this.reportSampleCompanyNumberObj.data.map(item =>{
			list.map(item =>{
        if(item.name){
          xData.push(item.name)
          data.push(item.number)
        }
      })
      this.reportSampleCompanyChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            axisLabel:{
              interval:0,
              rotate: 20
            },
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '填报率（%）',
            type: 'bar',
            barWidth: '60%',
            data: data,
            itemStyle:{
              color:'#1890ff'
            }
          }
        ]
      })
    },
    // 全省样本企业填报准确率
    reportSampleCompanyAccuracyFun(){
      this.reportSampleCompanyAccuracyChart = Chart.init(this.$refs.reportSampleCompanyAccuracy)
      this.reportSampleCompanyAccuracyOption()
    },
    reportSampleCompanyAccuracyOption(){
      var xData=[]
      var data=[]
      if(this.reportSampleCompanyAccuracyObj.data.length>0){
        this.reportSampleCompanyAccuracyObj.data.map(item =>{
          if(item.name){
            xData.push(item.name)
            data.push(item.number)
          }
        })
      }
      
      this.reportSampleCompanyAccuracyChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data:xData,
            axisLabel:{
              interval:0,
              rotate: 20
            },
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '准确率（%）',
            type: 'bar',
            barWidth: '60%',
            data: data,
            itemStyle:{
              color:'#1890ff'
            }
          }
        ]
      })
    }
  }
}
</script>

<style scoped>
.box {
  background-color: #fff;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  color: #000;
  font-weight: bold;
}
.cavasbox {
  box-sizing: border-box;
  width: 100%;
  height: 300px;
}
</style>